import React from "react";
import { Ring } from "react-spinners-css";
export default function Button(props) {
  return (
    <button
      type="button"
      class={` ${
        props?.fill ? "w-full" : "w-200"
      }  inline-flex items-center justify-center w-full px-4 py-4 text-base font-semibold text-white transition-all duration-200 border border-transparent rounded-md bg-green-700 focus:outline-none hover:opacity-90 focus:opacity-90 `}
      {...props}
    >
      {props?.loading ? <Ring size={25} color="white" /> : props?.title}
    </button>
  );
}
