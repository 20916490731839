import appScreenMockup from '../../assets/app-screen-mockup.png';
import AllStoresBtn from '../StoreButton/AllStoresBtn';

const DownloadPanel = () => {
  return (
    <section
      style={{ maxWidth: '1184px' }}
      className="w-full lg:px-16 xl:px-0 lg:py-16 mx-auto"
    >
      <div className="flex justify-between items-center py-11 px-4 bg-go md:px-10 lg:py-0">
        <div className="lg:pl-12">
          <h1 className="text-2xl mb-0 md:text-4xl font-bold text-white">
            Download Tap n' Go
          </h1>
          <p className="text-white">Experience the joy of smooth rides.</p>
          <AllStoresBtn />
        </div>
        <div className="width__img hidden lg:block">
          <img className="w-full object-cover" src={appScreenMockup} alt="" />
        </div>
      </div>
    </section>
  );
};

export default DownloadPanel;
