import Cookies from "js-cookie";

export const BASE_URL2 =
  process.env.REACT_APP_SERVER_URL || "https://api.tapngogh.com";

export const TICKETAPI = "https://api.mmt.itmsgh.com";
// "https://ghanatapngoapi.herokuapp.com";

class Auth {
  getToken() {
    return sessionStorage.getItem("/t") || null;
  }

  setCipher(token) {
    sessionStorage.setItem("/t", token);
  }

  clearCipher() {
    sessionStorage.clear();
    Cookies.remove("/t");
  }
}

export default new Auth();
