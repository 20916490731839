import './AllStoresBtn.css';
import AppStoreButton from './AppStoreButton';
import PlaystoreButton from './PlayStoreButton';
import UssdButton from './UssdButton';

const AllStoresBtn = () => {
  return (
    <div class="store__btn grid grid-cols-2 gap-4 justify-center items-center md:grid-cols-3 mt-8">
      <a
        aria-label="Download on the App Store"
        href="https://apps.apple.com/app/tap-and-go-ghana/id6444683769"
        target="_blank"
        rel="noreferrer"
      >
        <AppStoreButton />
      </a>
      <a
        aria-label="Download Tap and Go from Playstore"
        href="https://play.google.com/store/apps/details?id=com.itmsgh.tapngogh"
        target="_blank"
        rel="noreferrer"
      >
        <PlaystoreButton />
      </a>
      <div aria-label="Access via Ussd" className="hidden sm:block">
        <UssdButton />
      </div>
    </div>
  );
};

export default AllStoresBtn;
