import { Fragment } from 'react';
import './style.css';
import ayalolooBuses from '../../assets/ayaloloo-buses.png';
import rides from '../../assets/rides.jpg';
import uniShuttle from '../../assets/uni-shuttle.jpg';
import betterPayment from '../../assets/better-payment.jpg';
import AllStoresBtn from '../../components/StoreButton/AllStoresBtn';
import DownloadPanel from '../../components/DownloadPanel/DownloadPanel';
import Step from '../../components/Steps/Step';

const Innercity = () => {
  const steps = [
    {
      title: 'Sign up',
      description:
        "Download the Tap n' Go app and register your account which is your key to seamless inner-city travel experience.",
    },
    {
      title: 'Track, Identify & Wait',
      description:
        'See the bus in real-time, find the closest stop, and wait comfortably.',
    },
    {
      title: 'Tap in',
      description:
        "Use your Tap n' Go card for quick and secure payment. A simple tap as you board ensures a secure ride.",
    },
  ];

  return (
    <Fragment>
      <section
        id="innercity__header"
        className="flex justify-center items-center"
      >
        <div className="header__img flex h-screen overflow-hidden">
          <img
            className="w-full object-cover"
            src={ayalolooBuses}
            alt="ayaloloo buses"
          />
        </div>
        <div className="header__text flex flex-col justify-end md:justify-center items-start md:items-center h-full md:h-1/2 px-5 pb-20 md:p-0">
          <h1 className="text-4xl md:text-5xl font-bold text-white">
            Trotro/Aayalolo
          </h1>
          <p style={{ maxWidth: '50ch' }} className="text-white md:hidden">
            From cross country adventures to swift intercity hops and convenient
            inner-city escapes, we've got your travel need covered.
          </p>
          <AllStoresBtn />
        </div>
      </section>

      {/* seamless mobility */}
      <section id="seamless-mobitlity">
        <div className="content__wrapper secondary">
          <div className="content row-reverse">
            <h2>Your Smooth Ride to Urban Freedom</h2>
            <div className="image">
              <img src={rides} alt="" />
            </div>
            <div className="content__txt">
              <h2>Your Smooth Ride to Urban Freedom</h2>
              <p class="mt-6 text-base text-subtext">
                Tired of crowded buses and confusing routes? Aayalolo offers
                seamless urban mobility for everyone, from students rushing to
                class to everyday passengers going about their usual errands.
              </p>
              <AllStoresBtn />
            </div>
          </div>
        </div>
      </section>

      {/* university shuttle */}
      <section id="university-shuttle">
        <div className="content__wrapper">
          <div className="content">
            <h2>University Shuttle</h2>
            <div className="image h-407">
              <img src={uniShuttle} alt="" />
            </div>
            <div className="content__txt">
              <h2>University Shuttle</h2>
              <p class="mt-6 text-base text-subtext">
                Students and faculty members can now get timely, reliable rides
                within key university locations with our dedicated shuttles.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* comunity transport */}
      <section id="community-transport">
        <div className="content__wrapper">
          <div className="content row-reverse">
            <h2>Community Transport</h2>
            <div className="image">
              <img src={betterPayment} alt="" />
            </div>
            <div className="content__txt">
              <h2>Community Transport</h2>
              <p class="mt-6 text-base text-subtext">
                Aayalolo connects you! Our inclusive routes cater to diverse
                needs, providing a convenient, affordable way to explore your
                neighborhood or commute with ease.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* on board */}
      <section id="on-board">
        <div className="content__wrapper">
          <div className="content">
            <h2>Get on Board with Aayalolo</h2>
            <div className="image h-570">
              <img src={ayalolooBuses} alt="" />
            </div>
            <div className="content__txt">
              <h2>Get on Board with Aayalolo</h2>
              <div className="mt-10 sm:mt-5">
                {steps.map((step, idx) => (
                  <Step
                    title={step.title}
                    description={step.description}
                    number={idx + 1}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <DownloadPanel />
    </Fragment>
  );
};

export default Innercity;
