import React, { Suspense, Fragment } from "react";
import { Footer } from "../../components";
import { TopBarLoader } from "../../components/Loaders";
import PrivateRoute from "../../navigation/PrivateRoute";
import routes from "../../navigation/routes";
import AppHeader from "./AppHeader";

export default function Layout() {
  return (
    <Fragment>
      {/* <div className="font-nunito bg-gray-900"> */}
       <div>
        <AppHeader />
        <div
          className={
            "min-h-screen max-h-full overscroll-y-auto max-w-full"
          }
        >
          <main>
            <Suspense fallback={TopBarLoader()}>
              {routes.map((screen, i) => (
                <PrivateRoute
                  name={screen.name}
                  component={screen.component}
                  exact={screen.exact}
                  path={screen.path}
                  key={i}
                />
              ))}
            </Suspense>
          </main>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
}
