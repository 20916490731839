import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory, Link, NavLink } from 'react-router-dom';
import logo from '../../assets/tapngo.png';
import cookiesConfig from '../../utils/cookies.config';
import Transitions from '../../utils/Transitions';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import CarRentalSharpIcon from '@mui/icons-material/CarRentalSharp';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import LayersRoundedIcon from '@mui/icons-material/LayersRounded';
import { motion } from 'framer-motion/dist/framer-motion';
import Button from '../../components/Button';
import './AppHeader.css';
import { useLocation } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AppHeader() {
  const [showMenu, setShowMenu] = useState(false);
  const [showSolutions, setShowSolutions] = useState(false);
  const [showFeatures, setShowFeatures] = useState(false);
  const [open, setOpen] = useState(false);

  const howitworksSections = {
    tapngo: 'tapngo',
    intercity: 'intercity',
    innercity: 'innercity',
    privatecab: 'privatecab',
    localtaxi: 'localtaxi',
    railway: 'railway',
  };

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '-100%' },
  };

  const [isScrolled, setIsScrolled] = useState();
  const [notLandingPage, setNotLandingPage] = useState();
  const location = useLocation();
  const headerClasses =
    'sticky inset-x-0  top-0 w-full z-20 bg-transparent  bg-opacity-100 font-figtree';

  function isOrigin() {
    if (window.location.href === `${window.location.origin}/`) return true;
    if (window.location.href === `${window.location.origin}/#`) return true;
    else return false;
  }

  function mobileLinkClickHandeler() {
    window.scrollTo(0, 0);
    setShowMenu(!showMenu);
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    setNotLandingPage(() => !isOrigin());

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  // control logo text color
  const darkMode = isScrolled || notLandingPage || showMenu;

  return (
    <Fragment>
      {/* <header class="sticky inset-x-0  top-0 w-full z-20 bg-transparent  bg-opacity-100 backdrop-blur backdrop-filter font-figtree"> */}
      <header
        id="header"
        class={
          isScrolled || notLandingPage || showMenu
            ? `show-bg ${headerClasses}`
            : `hide-bg ${headerClasses}`
        }
      >
        <div
          style={{ maxWidth: '1232px' }}
          class="px-4 mx-auto sm:px-6 lg:px-12 xl:px-6 whitespace-nowrap"
        >
          <div class="flex items-center justify-between h-16 lg:h-20">
            <div class="flex-shrink-0">
              <a
                href="/"
                title=""
                class="flex justify-center items-center gap-2"
              >
                <img class="w-auto h-10" src={logo} alt="" />
                <svg
                  width="130"
                  height="32"
                  viewBox="0 0 130 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={classNames(
                    'mt-1',
                    showMenu ? 'block' : 'hidden lg:block'
                  )}
                >
                  <g clip-path="url(#clip0_1951_1409)">
                    <path
                      d="M100.207 21.6671C97.5646 23.2748 94.771 24.1936 91.9019 22.8155C90.4674 22.1265 89.1083 20.9015 88.2023 19.5234C85.7862 15.9252 86.3147 11.1019 89.1838 7.96299C91.6754 5.20686 95.4505 4.90062 99.9051 7.1974C102.17 4.97718 104.888 6.35525 107.682 6.04901C107.682 10.0301 107.682 13.7049 107.682 17.4563C107.682 19.1406 107.682 20.9015 107.682 22.5858C107.757 26.031 106.096 28.4043 103.152 29.8589C98.9236 31.926 94.62 31.5432 90.3919 29.8589C88.5043 29.0933 88.2023 27.409 89.8633 26.1841C90.7694 25.5716 91.9774 25.2654 93.1099 25.1888C94.62 25.0357 96.281 25.3419 97.7911 25.1123C99.9051 24.8826 100.811 23.5811 100.207 21.6671ZM97.3381 17.6095C99.3011 17.6095 100.66 16.3845 100.66 14.5471C100.736 12.6331 99.3011 11.3316 97.2626 11.4082C95.2995 11.4082 93.865 12.7097 93.865 14.4705C93.7895 16.2314 95.375 17.6095 97.3381 17.6095Z"
                      fill={darkMode ? '#030F1A' : '#fff'}
                    />
                    <path
                      d="M33.8408 6.27771C37.2384 5.43556 40.032 5.81835 41.1645 6.96674C43.2031 6.43083 45.0907 5.58868 47.0537 5.58868C50.1493 5.51212 52.5654 7.0433 54.0754 9.87599C56.265 14.1633 56.0385 18.2975 53.2449 22.202C50.9798 25.341 46.4497 26.1066 42.7501 24.116C41.089 23.2739 40.938 23.3504 40.787 25.2644C40.636 28.5565 38.673 30.7001 35.5019 31.0829C34.5203 31.236 33.9163 31.0064 33.9163 29.858C33.9163 22.5083 33.9163 15.1586 33.9163 7.80889C33.8408 7.4261 33.8408 6.89018 33.8408 6.27771ZM44.6376 11.4072C42.3726 11.4072 40.787 13.0915 40.7115 15.3883C40.7115 17.6085 42.3726 19.4459 44.5621 19.5225C46.9782 19.599 48.7903 17.7616 48.7903 15.3883C48.7148 13.0149 46.9782 11.4072 44.6376 11.4072Z"
                      fill={darkMode ? '#030F1A' : '#fff'}
                    />
                    <path
                      d="M118.933 25.264C113.119 25.264 108.362 20.9001 108.362 15.4644C108.362 10.1053 113.119 5.58828 118.857 5.51172C124.52 5.51172 129.427 10.1053 129.427 15.3879C129.503 20.8236 124.746 25.264 118.933 25.264ZM118.933 11.4068C116.668 11.4068 114.856 13.2442 114.856 15.3879C114.856 17.5315 116.743 19.4455 118.933 19.4455C121.122 19.4455 123.01 17.5315 123.085 15.3879C123.085 13.3208 121.122 11.4068 118.933 11.4068Z"
                      fill={darkMode ? '#030F1A' : '#fff'}
                    />
                    <path
                      d="M59.3613 15.235C59.3613 12.7086 59.4368 10.1821 59.3613 7.65567C59.3613 6.43072 59.7388 5.81824 60.9469 5.97136C62.9099 6.2776 65.0995 5.12921 66.836 6.89007C66.9871 7.04319 67.5911 6.66039 67.9686 6.50728C73.2537 3.90426 78.3879 7.04319 78.4634 12.9383C78.4634 16.1537 78.4634 19.3692 78.4634 22.6613C78.4634 24.7284 78.0104 25.1112 75.9718 24.8815C73.7823 24.5753 71.9702 22.6613 71.8947 20.3645C71.8192 18.5271 71.8947 16.6897 71.8947 14.8522C71.8192 12.7086 70.7622 11.4071 68.9501 11.4836C67.2136 11.5602 66.3075 12.7086 66.3075 14.9288C66.3075 16.6131 66.3075 18.374 66.3075 20.0583C66.232 22.891 64.1935 24.8815 61.3999 24.9581C60.0408 24.9581 59.4368 24.4987 59.4368 23.1206C59.4368 20.3645 59.3613 17.838 59.3613 15.235Z"
                      fill={darkMode ? '#030F1A' : '#fff'}
                    />
                    <path
                      d="M26.2154 13.3975C25.9889 11.7132 24.7054 11.0242 23.1198 10.7945C22.1383 10.6414 21.0813 10.7945 20.0998 10.7945C18.0612 10.7945 16.7022 9.72267 15.6451 7.73213C20.0998 5.74159 24.3279 4.44008 28.858 6.73686C31.1986 7.88525 32.5576 9.95235 32.7086 12.6319C32.8596 15.3115 32.8596 17.9145 32.7841 20.5941C32.7086 23.9627 29.462 26.0298 26.3664 24.7283C25.8379 24.4986 25.0829 24.5752 24.4789 24.6517C22.6668 24.7283 20.7038 25.2642 18.9672 24.958C15.8716 24.4221 14.0596 21.8956 14.1351 19.0629C14.2106 16.5364 16.5512 13.8569 19.4202 13.4741C21.6853 13.1678 23.9504 13.3975 26.2154 13.3975ZM23.2709 21.13C25.2339 21.13 26.3664 20.1347 26.2909 18.527C26.1399 16.3833 24.3279 17.0724 23.2709 16.9192C21.9118 16.7661 20.7793 17.6083 20.7793 19.1395C20.8548 20.6706 21.9118 21.13 23.2709 21.13Z"
                      fill={darkMode ? '#030F1A' : '#fff'}
                    />
                    <path
                      d="M3.11139 11.7127C0.54431 11.3299 0.242302 10.4878 0.997324 6.12392C1.52584 6.04736 2.12986 5.89424 3.11139 5.74112C3.11139 4.59273 3.11139 3.36778 3.11139 2.14284C3.03588 1.14757 3.4134 0.841329 4.39492 0.76477C7.49052 0.611651 9.15157 2.06628 9.98209 5.81768C11.0391 5.89424 12.1717 5.9708 13.4552 6.04736C13.4552 7.57854 13.6062 9.03317 13.3797 10.4112C13.3042 10.794 12.3227 11.4065 11.8696 11.4065C10.0576 11.2534 9.52908 12.019 9.75558 13.7033C9.83109 14.5454 9.83109 15.4641 9.75558 16.3829C9.60458 18.2203 10.0576 19.4452 12.0962 19.7515C13.6062 19.9811 14.0592 21.4358 13.9082 22.6607C13.8327 23.3498 13.0022 24.2685 12.3227 24.6513C7.79253 26.7949 3.18689 23.8857 3.11139 18.8328C3.03588 16.4594 3.11139 14.0861 3.11139 11.7127Z"
                      fill={darkMode ? '#030F1A' : '#fff'}
                    />
                    <path
                      d="M82.7669 9.72268C82.5404 10.0289 81.9364 10.3352 81.5588 10.182C80.9548 9.56957 81.1813 9.03365 81.3323 8.65085C81.7099 7.8087 82.0874 7.19623 80.9548 6.66031C79.4448 5.89472 78.9163 4.44009 79.6713 3.06203C80.1243 2.21988 81.4078 1.37772 82.3894 1.30116C84.1259 1.14805 84.6544 2.75579 85.032 4.21042C85.1075 4.44009 85.1075 4.74633 85.1075 4.97601C84.9564 6.04784 83.6729 8.26806 82.7669 9.72268Z"
                      fill={darkMode ? '#030F1A' : '#fff'}
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1951_1409">
                      <rect
                        width="128.882"
                        height="30.4706"
                        fill="white"
                        transform="translate(0.620117 0.765625)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>

            <div class="lg:flex lg:items-center lg:justify-end lg:space-x-6 sm:ml-auto">
              <div class="hidden pl-8 lg:flex lg:items-center lg:justify-center lg:gap-x-8">
                <Link
                  to="/ride-hailing"
                  class="text-base font-medium text-white hover:text-green-400 transition-all duration-200 hover:text-opacity-80"
                >
                  Taxi{' '}
                </Link>

                <Link
                  to="/innercity"
                  class="text-base font-medium text-white hover:text-green-400 transition-all duration-200 hover:text-opacity-80"
                >
                  Trotro/Aayalolo{' '}
                </Link>

                <Link
                  to="/intercity"
                  class="text-base font-medium text-white hover:text-green-400 transition-all duration-200 hover:text-opacity-80"
                >
                  Intercity Bus{' '}
                </Link>

                {/* <Link
                  to="https://bustickets.tapngogh.com/"
                  class="text-base font-medium text-white hover:text-green-400 transition-all duration-200 hover:text-opacity-80"
                >
                  Book Bus Ticket{' '}
                </Link> */}
              </div>

              <a
                href="https://bustickets.tapngogh.com/#/routes"
                target="_blank"
                rel="noreferrer"
                className="download-btn bg-green-600 text-white hover:bg-green-400 hover:text-white shadow-sm hidden lg:block rounded-md ml-4 px-3.5 py-2.5 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                // class="md:inline-flex items-center hidden justify-center px-3 sm:px-5 py-2.5 text-sm sm:text-base font-semibold transition-all duration-200 text-white hover:text-green-300  bg-white/20 hover:bg-white/40 focus:bg-white/40 rounded-lg"
                role="button"
              >
                {' '}
                Book a Bus{' '}
              </a>
            </div>

            <div className="flex gap-1">
              {/* mobile */}
              <a
                href="https://bustickets.tapngogh.com/#/routes"
                target="_blank"
                rel="noreferrer"
                className={classNames(
                  'download-btn bg-green-600 text-white hover:bg-green-400 hover:text-white shadow-sm lg:hidden block rounded-md ml-4 px-3.5 py-2.5 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white',
                  showMenu ? 'hidden' : 'block'
                )}
                // class="md:inline-flex items-center hidden justify-center px-3 sm:px-5 py-2.5 text-sm sm:text-base font-semibold transition-all duration-200 text-white hover:text-green-300  bg-white/20 hover:bg-white/40 focus:bg-white/40 rounded-lg"
                role="button"
              >
                {' '}
                Book a Bus{' '}
              </a>

              <button
                type="button"
                aria-expanded="false"
                onClick={() => setShowMenu(!showMenu)}
                class="inline-flex p-2 ml-1 text-green-600 transition-all duration-200 rounded-md sm:ml-4 lg:hidden focus:bg-white hover:bg-white"
              >
                {showMenu ? (
                  // {/* <!-- Menu open: "block", Menu closed: "hidden" --> */}
                  <svg
                    id="close__svg"
                    data-name="Layer 1"
                    class="block w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 14 14"
                    fill="none"
                    stroke="#22c55e"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2px"
                      d="m1,13L13,1M1,1l12,12"
                    />
                  </svg>
                ) : (
                  // {/* <!-- Menu open: "hidden", Menu closed: "block" --> */}
                  <svg
                    class="block w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#22c55e"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16m-7 6h7"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>

          {/**MOBILE MENU */}
          <Transitions
            show={showMenu}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div
              class="mobile__menu origin-top absolute inset-x-0 flex flex-col justify-between items-center w-full z-50 shadow-lg py-4 px-4 bg-white text-black focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu-button"
              tabindex="-1"
            >
              <NavLink
                activeClassName="active"
                onClick={() => mobileLinkClickHandeler()}
                to="/"
                class="text-base font-medium hover:text-green-400 transition-all duration-200 hover:text-opacity-80"
              >
                Home{' '}
              </NavLink>

              <NavLink
                activeClassName="active"
                onClick={() => mobileLinkClickHandeler()}
                to="/ride-hailing"
                class="text-base font-medium hover:text-green-400 transition-all duration-200 hover:text-opacity-80"
              >
                Taxi{' '}
              </NavLink>

              <NavLink
                activeClassName="active"
                onClick={() => mobileLinkClickHandeler()}
                to="/innercity"
                class="text-base font-medium hover:text-green-400 transition-all duration-200 hover:text-opacity-80"
              >
                Trotro/Aayalolo{' '}
              </NavLink>

              <NavLink
                activeClassName="active"
                onClick={() => mobileLinkClickHandeler()}
                to="/intercity"
                class="text-base font-medium hover:text-green-400 transition-all duration-200 hover:text-opacity-80"
              >
                Intercity Bus{' '}
              </NavLink>
              <a
                href="https://onelink.to/azq52t"
                target="_blank"
                rel="noreferrer"
                class="download__btn lg:w-auto flex justify-center items-center rounded-default bg-green-600 px-3.5 py-2.5 mb-8 text-lg font-semibold text-white shadow-sm hover:bg-green-400 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Download
              </a>
            </div>
          </Transitions>
        </div>
      </header>
      {/* <nav class="bg-white shadow">
        <div class="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
          <div class="flex justify-between h-16">
            <div class="flex px-2 lg:px-0">
              <div class="flex-shrink-0 flex items-center">
                <img
                  class="block lg:hidden h-8 w-auto"
                  src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                  alt="Workflow"
                />
                <img
                  class="hidden lg:block h-8 w-auto"
                  src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                  alt="Workflow"
                />
              </div>
              <div class="hidden lg:ml-6 lg:flex lg:space-x-8">
                <a
                  href="#"
                  class="border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                >
                  Dashboard
                </a>
                <a
                  href="#"
                  class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                >
                  Team
                </a>
                <a
                  href="#"
                  class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                >
                  Projects
                </a>
                <a
                  href="#"
                  class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                >
                  Calendar
                </a>
              </div>
            </div>
            <div class="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
              <div class="max-w-lg w-full lg:max-w-xs">
                <label for="search" class="sr-only">
                  Search
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      class="h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    id="search"
                    name="search"
                    class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Search"
                    type="search"
                  />
                </div>
              </div>
            </div>
            <div class="flex items-center lg:hidden">
              <button
                type="button"
                class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span class="sr-only">Open main menu</span>

                <svg
                  class="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>

                <svg
                  class="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="hidden lg:ml-4 lg:flex lg:items-center">
              <button class="flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span class="sr-only">View notifications</span>

                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg>
              </button>

              <div class="ml-4 relative flex-shrink-0">
                <div>
                  <button
                    type="button"
                    class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    id="user-menu-button"
                    aria-expanded="false"
                    onClick={() => setShowMenu(!showMenu)}
                    aria-haspopup="true"
                  >
                    <span class="sr-only">Open user menu</span>
                    <img
                      class="h-8 w-8 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixqx=ah3lxr8uqw&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="lg:hidden" id="mobile-menu">
          <div class="pt-2 pb-3 space-y-1">
            <a
              href="#"
              class="bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >
              Dashboard
            </a>
            <a
              href="#"
              class="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >
              Team
            </a>
            <a
              href="#"
              class="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >
              Projects
            </a>
            <a
              href="#"
              class="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >
              Calendar
            </a>
          </div>
          <div class="pt-4 pb-3 border-t border-gray-200">
            <div class="flex items-center px-4">
              <div class="flex-shrink-0">
                <img
                  class="h-10 w-10 rounded-full"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixqx=ah3lxr8uqw&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
              </div>
              <div class="ml-3">
                <div class="text-base font-medium text-gray-800">Tom Cook</div>
                <div class="text-sm font-medium text-gray-500">
                  tom@example.com
                </div>
              </div>
              <button class="ml-auto flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span class="sr-only">View notifications</span>

                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg>
              </button>
            </div>
            <div class="mt-3 space-y-1">
              <a
                href="#"
                class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
              >
                Your Profile
              </a>
              <a
                href="#"
                class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
              >
                Settings
              </a>
              <a
                href="#"
                class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
              >
                Sign out
              </a>
            </div>
          </div>
        </div>
      </nav> */}
    </Fragment>
  );
}
