import playstore from '../../assets/playstore.png';

const PlaystoreButton = () => {
  return (
    // <svg
    //   id="playstore-btn"
    //   className="store-btn"
    //   data-name="Layer 2"
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 75.47 22.36"
    // >
    //   <defs>
    //     <style></style>
    //   </defs>
    //   <g id="Layer_3" data-name="Layer 3">
    //     <g>
    //       <rect class="cls-2" width="75.47" height="22.36" rx="2.8" ry="2.8" />
    //       <path
    //         class="cls-5"
    //         style={{ strokeWidth: '0px', fill: '#a6a6a6' }}
    //         d="m72.68.45c1.29,0,2.35,1.05,2.35,2.35v16.77c0,1.29-1.05,2.35-2.35,2.35H2.8c-1.29,0-2.35-1.05-2.35-2.35V2.8C.45,1.5,1.5.45,2.8.45h69.88M72.68,0H2.8C1.26,0,0,1.26,0,2.8v16.77c0,1.54,1.26,2.8,2.8,2.8h69.88c1.54,0,2.8-1.26,2.8-2.8V2.8C75.47,1.26,74.21,0,72.68,0h0Z"
    //       />
    //       <g>
    //         <g>
    //           <path
    //             class="cls-4"
    //             style={{ strokeWidth: '0px', fill: '#fff' }}
    //             d="m26.51,5.73c0,.47-.14.84-.42,1.12-.31.33-.72.5-1.23.5s-.9-.17-1.24-.51-.51-.75-.51-1.25.17-.91.51-1.25.75-.51,1.24-.51c.25,0,.48.04.7.13.22.09.4.21.54.37l-.31.31c-.1-.12-.24-.22-.4-.29-.16-.07-.34-.1-.53-.1-.37,0-.67.13-.93.38-.25.26-.38.58-.38.96s.12.7.38.96c.25.25.56.38.93.38.33,0,.61-.09.83-.28s.35-.45.38-.77h-1.22v-.4h1.63c.02.09.02.17.02.25Z"
    //           />
    //           <path
    //             class="cls-4"
    //             style={{ strokeWidth: '0px', fill: '#fff' }}
    //             d="m24.86,7.4c-.5,0-.93-.18-1.28-.52-.35-.35-.52-.78-.52-1.29s.18-.94.52-1.29c.35-.35.78-.52,1.28-.52.25,0,.5.05.72.14.22.09.41.22.56.38l.04.04-.39.39-.04-.05c-.1-.12-.23-.21-.38-.28-.16-.07-.33-.1-.5-.1-.35,0-.65.12-.89.36-.24.24-.36.55-.36.92s.12.68.36.92c.24.24.54.36.89.36.32,0,.59-.09.8-.27.19-.16.31-.39.36-.67h-1.21v-.52h1.73v.05c.02.09.03.18.03.26,0,.48-.15.87-.43,1.16-.32.34-.75.51-1.27.51Zm0-3.5c-.47,0-.87.16-1.2.49-.32.33-.49.73-.49,1.21s.17.88.49,1.21c.33.32.73.49,1.2.49s.89-.16,1.19-.48c.27-.27.4-.63.4-1.08,0-.06,0-.13-.01-.2h-1.52v.29h1.22v.06c-.04.34-.18.62-.41.81-.23.2-.52.29-.87.29-.38,0-.71-.13-.97-.4-.26-.27-.39-.6-.39-1s.13-.74.39-1c.26-.26.59-.4.97-.4.19,0,.38.04.55.11.15.06.28.15.38.26l.23-.23c-.13-.13-.29-.24-.48-.31-.21-.08-.44-.13-.68-.13Z"
    //           />
    //         </g>
    //         <g>
    //           <path
    //             class="cls-4"
    //             style={{ strokeWidth: '0px', fill: '#fff' }}
    //             d="m29.09,3.91v.41h-1.53v1.06h1.38v.4h-1.38v1.06h1.53v.41h-1.96v-3.35h1.96Z"
    //           />
    //           <path
    //             class="cls-4"
    //             style={{ strokeWidth: '0px', fill: '#fff' }}
    //             d="m29.14,7.32h-2.07v-3.47h2.07v.53h-1.53v.95h1.38v.52h-1.38v.95h1.53v.53Zm-1.96-.12h1.84v-.3h-1.53v-1.18h1.38v-.29h-1.38v-1.18h1.53v-.3h-1.84v3.24Z"
    //           />
    //         </g>
    //         <g>
    //           <path
    //             class="cls-4"
    //             style={{ strokeWidth: '0px', fill: '#fff' }}
    //             d="m30.9,4.32v2.94h-.43v-2.94h-.94v-.41h2.3v.41h-.94Z"
    //           />
    //           <path
    //             class="cls-4"
    //             style={{ strokeWidth: '0px', fill: '#fff' }}
    //             d="m30.96,7.32h-.55v-2.94h-.94v-.53h2.42v.53h-.94v2.94Zm-.43-.12h.32v-2.94h.94v-.3h-2.19v.3h.94v2.94Z"
    //           />
    //         </g>
    //         <g>
    //           <path
    //             class="cls-4"
    //             style={{ strokeWidth: '0px', fill: '#fff' }}
    //             d="m33.94,7.27h-.43v-3.35h.43v3.35Z"
    //           />
    //           <path
    //             class="cls-4"
    //             style={{ strokeWidth: '0px', fill: '#fff' }}
    //             d="m34,7.32h-.55v-3.47h.55v3.47Zm-.43-.12h.32v-3.24h-.32v3.24Z"
    //           />
    //         </g>
    //         <g>
    //           <path
    //             class="cls-4"
    //             style={{ strokeWidth: '0px', fill: '#fff' }}
    //             d="m35.85,4.32v2.94h-.43v-2.94h-.94v-.41h2.3v.41h-.94Z"
    //           />
    //           <path
    //             class="cls-4"
    //             style={{ strokeWidth: '0px', fill: '#fff' }}
    //             d="m35.91,7.32h-.55v-2.94h-.94v-.53h2.42v.53h-.94v2.94Zm-.43-.12h.32v-2.94h.94v-.3h-2.19v.3h.94v2.94Z"
    //           />
    //         </g>
    //         <g>
    //           <path
    //             class="cls-4"
    //             style={{ strokeWidth: '0px', fill: '#fff' }}
    //             d="m41.64,5.59c0,.5-.17.91-.5,1.25-.33.34-.74.51-1.23.51s-.9-.17-1.23-.51c-.33-.33-.5-.75-.5-1.25s.17-.91.5-1.25c.33-.34.74-.51,1.23-.51s.9.17,1.23.51c.33.33.5.75.5,1.24Zm-3.01,0c0,.39.12.71.37.96.25.25.55.38.92.38s.67-.13.91-.38c.25-.25.37-.57.37-.96s-.12-.71-.37-.96c-.24-.25-.55-.38-.91-.38s-.67.13-.92.38c-.24.25-.37.57-.37.96Z"
    //           />
    //           <path
    //             class="cls-4"
    //             style={{ strokeWidth: '0px', fill: '#fff' }}
    //             d="m39.92,7.4c-.5,0-.93-.18-1.27-.52-.34-.34-.51-.78-.51-1.29s.17-.94.51-1.29c.34-.35.77-.52,1.27-.52s.93.18,1.27.53c.34.34.51.78.51,1.28s-.17.94-.51,1.29c-.34.35-.77.52-1.27.52Zm0-3.5c-.47,0-.87.16-1.19.49-.32.32-.48.73-.48,1.21s.16.88.48,1.21c.32.32.72.49,1.19.49s.87-.16,1.19-.49c.32-.32.48-.73.48-1.21s-.16-.88-.48-1.2c-.32-.33-.72-.49-1.19-.49Zm0,3.09c-.38,0-.7-.13-.96-.4-.25-.26-.38-.6-.38-1s.13-.74.38-1c.26-.26.58-.4.96-.4s.7.13.95.4c.26.26.39.6.39,1s-.13.74-.39,1c-.25.26-.57.4-.95.4Zm0-2.68c-.35,0-.64.12-.88.36-.23.24-.35.55-.35.92s.12.68.35.92c.23.24.53.36.88.36s.64-.12.87-.36c.24-.24.35-.55.35-.92s-.12-.68-.35-.92c-.23-.24-.52-.36-.87-.36Z"
    //           />
    //         </g>
    //         <g>
    //           <path
    //             class="cls-4"
    //             style={{ strokeWidth: '0px', fill: '#fff' }}
    //             d="m42.68,7.27h-.43v-3.35h.53l1.63,2.61h.02l-.02-.65v-1.96h.43v3.35h-.45l-1.71-2.74h-.02l.02.65v2.09Z"
    //           />
    //           <path
    //             class="cls-4"
    //             style={{ strokeWidth: '0px', fill: '#fff' }}
    //             d="m44.89,7.32h-.54l-.02-.03-1.61-2.59v.47s.01,2.15.01,2.15h-.55v-3.47h.62l.02.03,1.54,2.46v-.47s-.01-2.02-.01-2.02h.55v3.47Zm-.48-.12h.36v-3.24h-.32v1.91l.02.7h-.11l-.02-.03-1.61-2.58h-.44v3.24h.32v-2.03l-.02-.7h.11l.02.03,1.69,2.71Z"
    //           />
    //         </g>
    //       </g>
    //       <path
    //         class="cls-4"
    //         style={{ strokeWidth: '0px', fill: '#fff' }}
    //         d="m38.09,12.16c-1.31,0-2.39,1-2.39,2.38s1.07,2.38,2.39,2.38,2.39-1.01,2.39-2.38-1.07-2.38-2.39-2.38Zm0,3.82c-.72,0-1.34-.59-1.34-1.44s.62-1.44,1.34-1.44,1.34.59,1.34,1.44-.62,1.44-1.34,1.44Zm-5.21-3.82c-1.31,0-2.39,1-2.39,2.38s1.07,2.38,2.39,2.38,2.39-1.01,2.39-2.38-1.07-2.38-2.39-2.38Zm0,3.82c-.72,0-1.34-.59-1.34-1.44s.62-1.44,1.34-1.44,1.34.59,1.34,1.44-.62,1.44-1.34,1.44Zm-6.19-3.09v1.01h2.41c-.07.57-.26.98-.55,1.27-.35.35-.9.74-1.86.74-1.49,0-2.65-1.2-2.65-2.68s1.16-2.68,2.65-2.68c.8,0,1.39.32,1.82.72l.71-.71c-.6-.58-1.41-1.02-2.53-1.02-2.04,0-3.75,1.66-3.75,3.69s1.71,3.69,3.75,3.69c1.1,0,1.93-.36,2.58-1.04.67-.67.87-1.6.87-2.36,0-.23-.02-.45-.05-.63h-3.4Zm25.33.78c-.2-.53-.8-1.51-2.04-1.51s-2.24.96-2.24,2.38c0,1.33,1.01,2.38,2.36,2.38,1.09,0,1.72-.67,1.98-1.05l-.81-.54c-.27.4-.64.66-1.17.66s-.91-.24-1.15-.72l3.18-1.31-.11-.27Zm-3.24.79c-.03-.92.71-1.39,1.24-1.39.41,0,.77.21.88.5l-2.13.88Zm-2.58,2.31h1.04v-6.99h-1.04v6.99Zm-1.71-4.08h-.04c-.23-.28-.68-.53-1.25-.53-1.19,0-2.28,1.04-2.28,2.39s1.09,2.37,2.28,2.37c.57,0,1.02-.25,1.25-.54h.04v.34c0,.91-.49,1.4-1.27,1.4-.64,0-1.04-.46-1.2-.85l-.91.38c.26.63.95,1.41,2.11,1.41,1.22,0,2.26-.72,2.26-2.48v-4.27h-.99v.39Zm-1.2,3.29c-.72,0-1.32-.6-1.32-1.43s.6-1.45,1.32-1.45,1.27.61,1.27,1.45-.56,1.43-1.27,1.43Zm13.63-6.2h-2.5v6.99h1.04v-2.65h1.46c1.16,0,2.29-.84,2.29-2.17s-1.14-2.17-2.29-2.17Zm.03,3.37h-1.48v-2.4h1.48c.78,0,1.22.65,1.22,1.2s-.44,1.2-1.22,1.2Zm6.45-1c-.76,0-1.54.33-1.86,1.07l.93.39c.2-.39.57-.51.95-.51.54,0,1.09.32,1.1.9v.07c-.19-.11-.59-.27-1.09-.27-1,0-2.01.55-2.01,1.57,0,.94.82,1.54,1.74,1.54.7,0,1.09-.31,1.33-.68h.04v.54h1.01v-2.68c0-1.24-.93-1.93-2.12-1.93Zm-.13,3.83c-.34,0-.82-.17-.82-.59,0-.54.59-.75,1.11-.75.46,0,.67.1.95.23-.08.65-.64,1.11-1.24,1.11Zm5.92-3.68l-1.2,3.03h-.04l-1.24-3.03h-1.12l1.86,4.23-1.06,2.36h1.09l2.87-6.59h-1.16Zm-9.4,4.47h1.04v-6.99h-1.04v6.99Z"
    //       />
    //       <g>
    //         <path
    //           class="cls-3"
    //           style={{ strokeWidth: '0px', fill: '#ea4335' }}
    //           d="m11.58,10.86l-5.95,6.32s0,0,0,0c.18.69.81,1.19,1.55,1.19.3,0,.58-.08.82-.22h.02s6.7-3.88,6.7-3.88l-3.14-3.41Z"
    //         />
    //         <path
    //           class="cls-7"
    //           style={{ strokeWidth: '0px', fill: '#fbbc04' }}
    //           d="m17.6,9.78h0s-2.89-1.68-2.89-1.68l-3.26,2.9,3.27,3.27,2.88-1.66c.5-.27.85-.8.85-1.42s-.34-1.14-.84-1.41Z"
    //         />
    //         <path
    //           class="cls-1"
    //           style={{ strokeWidth: '0px', fill: '#4285f4' }}
    //           d="m5.63,5.19c-.04.13-.05.27-.05.41v11.16c0,.14.02.28.05.41l6.16-6.16-6.16-5.83Z"
    //         />
    //         <path
    //           class="cls-6"
    //           style={{ strokeWidth: '0px', fill: '#34a853' }}
    //           d="m11.63,11.18l3.08-3.08-6.69-3.88c-.24-.15-.53-.23-.83-.23-.74,0-1.37.51-1.55,1.19,0,0,0,0,0,0l6,6Z"
    //         />
    //       </g>
    //     </g>
    //   </g>
    // </svg>
    <img id="playstore-btn" src={playstore} alt="" />
  );
};

export default PlaystoreButton;
