import './Step.css';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Step = ({ title, description, number, invert = false }) => {
  return (
    <div class="group step flex items-start gap-5 mb-10">
      <dt className="flex items-center">
        <div
          class={classNames(
            'step__marker flex items-center justify-center font-medium rounded-full',
            invert ? 'text-go bg-white' : 'text-white bg-go '
          )}
        >
          {number}
        </div>
      </dt>
      <div className="step-txt grid justify-start transition-height duration-500 ease-in-out">
        <h4
          class={classNames(
            'mb-0 text-xl font-semibold leading-6',
            invert ? 'text-white' : 'text-default'
          )}
        >
          {title}
        </h4>
        <dd
          style={{ maxWidth: '40ch' }}
          className={classNames(
            'mt-2 text-base ',
            invert ? 'text-white' : 'text-subtext'
          )}
        >
          {description}
        </dd>
      </div>
    </div>
  );
};

export default Step;
