const Web = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <path
          id="Vector"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM11.0511 3.49465C11.4347 3.11289 11.7544 3 12 3C12.2456 3 12.5653 3.11289 12.9489 3.49465C13.3365 3.88046 13.7316 4.48883 14.0849 5.32007C14.7001 6.76773 15.1289 8.75039 15.2281 11H8.7719C8.87106 8.75039 9.29987 6.76773 9.91513 5.32007C10.2684 4.48883 10.6635 3.88046 11.0511 3.49465ZM6.77014 11C6.87027 8.526 7.33921 6.26781 8.07447 4.53779C8.19472 4.25484 8.32375 3.98211 8.46165 3.72222C5.54238 4.97169 3.41748 7.72027 3.05493 11H6.77014ZM3.05493 13H6.77014C6.87027 15.474 7.33921 17.7322 8.07447 19.4622C8.19472 19.7452 8.32375 20.0179 8.46165 20.2778C5.54238 19.0283 3.41748 16.2797 3.05493 13ZM8.7719 13H15.2281C15.1289 15.2496 14.7001 17.2323 14.0849 18.6799C13.7316 19.5112 13.3365 20.1195 12.9489 20.5053C12.5653 20.8871 12.2456 21 12 21C11.7544 21 11.4347 20.8871 11.0511 20.5053C10.6635 20.1195 10.2684 19.5112 9.91513 18.6799C9.29987 17.2323 8.87106 15.2496 8.7719 13ZM17.2299 13C17.1297 15.474 16.6608 17.7322 15.9255 19.4622C15.8053 19.7452 15.6762 20.0179 15.5384 20.2778C18.4576 19.0283 20.5825 16.2797 20.9451 13H17.2299ZM20.9451 11C20.5825 7.72027 18.4576 4.97169 15.5384 3.72222C15.6762 3.98211 15.8053 4.25484 15.9255 4.53779C16.6608 6.26781 17.1297 8.526 17.2299 11H20.9451Z"
          fill="#030F1A"
        />
      </g>
    </svg>
  );
};

export default Web;
