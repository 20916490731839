import appstore from '../../assets/appstore.png';

const AppStoreButton = () => {
  return (
    // <svg
    //   id="appstore-btn"
    //   className="store-btn"
    //   data-name="Layer 2"
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 66.9 22.36"
    // >
    //   <defs>
    //     <style></style>
    //   </defs>
    //   <g id="Layer_3" data-name="Layer 3">
    //     <g>
    //       <g>
    //         <g>
    //           <path
    //             class="cls-3"
    //             style={{strokeWidth: '0px', fill: '#a6a6a6'}}
    //             d="m61.57,0H5.33C5.13,0,4.92,0,4.72,0c-.17,0-.34,0-.51,0-.38,0-.75.04-1.12.1-.37.06-.73.18-1.06.35-.33.17-.64.39-.9.66-.27.26-.49.57-.66.91-.17.33-.29.69-.35,1.06-.06.37-.1.74-.1,1.12,0,.17,0,.34,0,.51v12.92c0,.17,0,.34,0,.52,0,.38.04.75.1,1.12.06.37.18.73.35,1.06.17.33.39.64.66.9.26.27.57.49.9.66.33.17.69.29,1.06.35.37.06.74.09,1.12.1.17,0,.34,0,.51,0,.2,0,.41,0,.61,0h56.24c.2,0,.41,0,.61,0,.17,0,.35,0,.52,0,.37,0,.75-.04,1.12-.1.37-.06.73-.18,1.07-.35.33-.17.64-.39.9-.66.27-.26.49-.57.66-.9.17-.34.29-.69.35-1.06.06-.37.1-.74.1-1.12,0-.17,0-.34,0-.52,0-.2,0-.41,0-.61V5.33c0-.2,0-.41,0-.61,0-.17,0-.34,0-.51,0-.38-.04-.75-.1-1.12-.06-.37-.18-.73-.35-1.06-.35-.67-.89-1.22-1.56-1.57-.34-.17-.7-.29-1.07-.35-.37-.06-.74-.09-1.12-.1-.17,0-.35,0-.52,0-.2,0-.41,0-.61,0h0Z"
    //           />
    //           <path
    //             class="cls-1"
    //             style={{strokeWidth: '0px'}}
    //             d="m4.72,21.87c-.17,0-.34,0-.51,0-.35,0-.7-.04-1.04-.09-.32-.06-.63-.16-.93-.31-.29-.15-.55-.34-.78-.57-.23-.23-.42-.49-.57-.78-.15-.29-.25-.6-.3-.93-.06-.35-.09-.7-.09-1.05,0-.12,0-.51,0-.51V4.72s0-.39,0-.5c0-.35.04-.7.09-1.05.05-.32.16-.64.3-.93.15-.29.34-.55.57-.78.23-.23.49-.42.78-.57.29-.15.6-.25.92-.3.35-.06.7-.09,1.05-.09h.5s57.45,0,57.45,0h.51c.35.01.7.04,1.04.1.32.06.64.16.93.31.58.3,1.05.77,1.35,1.35.15.29.25.6.3.92.06.35.09.7.1,1.06,0,.16,0,.33,0,.5,0,.21,0,.41,0,.61v11.7c0,.2,0,.4,0,.6,0,.18,0,.35,0,.52,0,.35-.04.69-.1,1.04-.05.32-.15.64-.3.93-.15.29-.34.55-.57.77-.23.23-.49.42-.78.57-.29.15-.61.25-.93.31-.35.06-.69.09-1.04.09-.16,0-.34,0-.5,0h-.61s-56.85,0-56.85,0Z"
    //           />
    //         </g>
    //         <g id="_Group_" data-name=" Group ">
    //           <g id="_Group_2" data-name=" Group 2">
    //             <g id="_Group_3" data-name=" Group 3">
    //               <path
    //                 id="_Path_"
    //                 data-name=" Path "
    //                 class="cls-2"
    //                 style={{strokeWidth: '0px', fill: '#fff'}}
    //                 d="m13.85,11.35c.01-.95.51-1.82,1.32-2.32-.51-.73-1.34-1.18-2.23-1.21-.94-.1-1.85.56-2.33.56s-1.22-.55-2.02-.54c-1.04.03-1.99.61-2.5,1.53-1.08,1.87-.27,4.62.76,6.14.52.74,1.12,1.57,1.92,1.54.78-.03,1.07-.49,2-.49s1.2.49,2.01.48c.83-.01,1.36-.74,1.86-1.49.37-.53.66-1.11.85-1.73-.99-.42-1.63-1.39-1.63-2.46Z"
    //               />
    //               <path
    //                 id="_Path_2"
    //                 data-name=" Path 2"
    //                 class="cls-2"
    //                 style={{strokeWidth: '0px', fill: '#fff'}}
    //                 d="m12.32,6.83c.45-.54.68-1.24.62-1.95-.69.07-1.33.4-1.79.93-.45.52-.68,1.19-.64,1.88.7,0,1.37-.31,1.81-.86Z"
    //               />
    //             </g>
    //           </g>
    //           <g>
    //             <path
    //               class="cls-2"
    //               style={{strokeWidth: '0px', fill: '#fff'}}
    //               d="m23.65,15.17h-2.65l-.64,1.88h-1.12l2.51-6.94h1.16l2.51,6.94h-1.14l-.63-1.88Zm-2.37-.87h2.1l-1.03-3.05h-.03l-1.03,3.05Z"
    //             />
    //             <path
    //               class="cls-2"
    //               style={{strokeWidth: '0px', fill: '#fff'}}
    //               d="m30.84,14.52c0,1.57-.84,2.58-2.11,2.58-.66.03-1.28-.31-1.59-.89h-.02v2.51h-1.04v-6.74h1.01v.84h.02c.33-.57.95-.92,1.61-.89,1.28,0,2.13,1.02,2.13,2.58Zm-1.07,0c0-1.02-.53-1.7-1.34-1.7s-1.33.69-1.33,1.7.53,1.7,1.33,1.7,1.34-.67,1.34-1.7Z"
    //             />
    //             <path
    //               class="cls-2"
    //               style={{strokeWidth: '0px', fill: '#fff'}}
    //               d="m36.41,14.52c0,1.57-.84,2.58-2.11,2.58-.66.03-1.28-.31-1.59-.89h-.02v2.51h-1.04v-6.74h1.01v.84h.02c.33-.57.95-.92,1.61-.89,1.28,0,2.13,1.02,2.13,2.58Zm-1.07,0c0-1.02-.53-1.7-1.34-1.7s-1.33.69-1.33,1.7.53,1.7,1.33,1.7,1.34-.67,1.34-1.7h0Z"
    //             />
    //             <path
    //               class="cls-2"
    //               style={{strokeWidth: '0px', fill: '#fff'}}
    //               d="m40.09,15.11c.08.69.75,1.14,1.66,1.14s1.51-.45,1.51-1.07c0-.54-.38-.86-1.28-1.08l-.9-.22c-1.27-.31-1.87-.9-1.87-1.87,0-1.2,1.04-2.02,2.53-2.02s2.47.82,2.51,2.02h-1.05c-.06-.69-.64-1.11-1.47-1.11s-1.41.42-1.41,1.04c0,.49.37.78,1.26,1l.76.19c1.42.34,2.02.91,2.02,1.92,0,1.3-1.03,2.11-2.68,2.11-1.54,0-2.58-.79-2.65-2.05h1.06Z"
    //             />
    //             <path
    //               class="cls-2"
    //               style={{strokeWidth: '0px', fill: '#fff'}}
    //               d="m46.59,10.79v1.2h.96v.82h-.96v2.79c0,.43.19.64.62.64.11,0,.23-.01.34-.02v.82c-.19.04-.38.05-.58.05-1.02,0-1.42-.38-1.42-1.37v-2.9h-.74v-.82h.74v-1.2h1.04Z"
    //             />
    //             <path
    //               class="cls-2"
    //               style={{strokeWidth: '0px', fill: '#fff'}}
    //               d="m48.11,14.52c0-1.59.94-2.59,2.4-2.59s2.4,1,2.4,2.59-.93,2.59-2.4,2.59-2.4-1-2.4-2.59Zm3.74,0c0-1.09-.5-1.74-1.34-1.74s-1.34.65-1.34,1.74.5,1.74,1.34,1.74,1.34-.64,1.34-1.74h0Z"
    //             />
    //             <path
    //               class="cls-2"
    //               style={{strokeWidth: '0px', fill: '#fff'}}
    //               d="m53.77,11.99h.99v.86h.02c.14-.55.65-.94,1.22-.91.12,0,.24.01.36.04v.97c-.15-.05-.31-.07-.47-.06-.58-.02-1.07.43-1.09,1,0,.05,0,.11,0,.16v3h-1.04v-5.06Z"
    //             />
    //             <path
    //               class="cls-2"
    //               style={{strokeWidth: '0px', fill: '#fff'}}
    //               d="m61.15,15.56c-.14.92-1.03,1.55-2.18,1.55-1.47,0-2.39-.99-2.39-2.57s.92-2.62,2.34-2.62,2.28.96,2.28,2.5v.36h-3.57v.06c-.06.73.47,1.37,1.2,1.43.05,0,.11,0,.16,0,.5.05.98-.24,1.17-.71h.99Zm-3.51-1.51h2.53c.04-.67-.48-1.25-1.15-1.28-.03,0-.06,0-.09,0-.71,0-1.28.57-1.29,1.27,0,0,0,0,0,.01Z"
    //             />
    //           </g>
    //         </g>
    //       </g>
    //       <g id="_Group_4" data-name=" Group 4">
    //         <g>
    //           <path
    //             class="cls-2"
    //             style={{strokeWidth: '0px', fill: '#fff'}}
    //             d="m21.15,4.88c.81-.06,1.52.55,1.58,1.37,0,.1,0,.19,0,.29,0,1.07-.58,1.68-1.57,1.68h-1.2v-3.34h1.2Zm-.69,2.86h.63c.58.03,1.07-.41,1.11-.98,0-.07,0-.14,0-.22.08-.58-.32-1.11-.9-1.18-.07,0-.13-.01-.2,0h-.63v2.39Z"
    //           />
    //           <path
    //             class="cls-2"
    //             style={{strokeWidth: '0px', fill: '#fff'}}
    //             d="m23.3,6.96c-.06-.66.42-1.24,1.07-1.3.66-.06,1.24.42,1.3,1.07,0,.08,0,.15,0,.23.06.66-.42,1.24-1.07,1.3-.66.06-1.24-.42-1.3-1.07,0-.08,0-.15,0-.23Zm1.86,0c0-.55-.25-.86-.68-.86s-.67.32-.67.86.24.87.67.87c.43,0,.68-.32.68-.87h0Z"
    //           />
    //           <path
    //             class="cls-2"
    //             style={{strokeWidth: '0px', fill: '#fff'}}
    //             d="m28.83,8.22h-.52l-.52-1.85h-.04l-.52,1.85h-.51l-.69-2.52h.5l.45,1.92h.04l.52-1.92h.48l.52,1.92h.04l.45-1.92h.5l-.69,2.52Z"
    //           />
    //           <path
    //             class="cls-2"
    //             style={{strokeWidth: '0px', fill: '#fff'}}
    //             d="m30.11,5.7h.48v.4h.04c.13-.29.43-.47.75-.45.45-.03.84.3.88.76,0,.06,0,.12,0,.18v1.63h-.5v-1.5c0-.4-.18-.61-.54-.61-.32-.01-.59.23-.6.55,0,.03,0,.06,0,.09v1.47h-.5v-2.52Z"
    //           />
    //           <path class="cls-2" style={{strokeWidth: '0px', fill: '#fff'}} d="m33.04,4.72h.5v3.5h-.5v-3.5Z" />
    //           <path
    //             class="cls-2"
    //             style={{strokeWidth: '0px', fill: '#fff'}}
    //             d="m34.22,6.96c-.06-.66.42-1.24,1.07-1.3.66-.06,1.24.42,1.3,1.07,0,.08,0,.15,0,.23.06.66-.42,1.24-1.07,1.3s-1.24-.42-1.3-1.07c0-.08,0-.15,0-.23Zm1.86,0c0-.55-.25-.86-.68-.86s-.67.32-.67.86.24.87.67.87c.43,0,.68-.32.68-.87Z"
    //           />
    //           <path
    //             class="cls-2"
    //             style={{strokeWidth: '0px', fill: '#fff'}}
    //             d="m37.12,7.5c0-.45.34-.71.94-.75l.68-.04v-.22c0-.27-.18-.42-.52-.42-.28,0-.47.1-.52.28h-.48c.05-.43.46-.71,1.03-.71.63,0,.99.31.99.85v1.72h-.48v-.35h-.04c-.16.26-.45.41-.76.4-.42.04-.79-.26-.84-.68,0-.03,0-.05,0-.08Zm1.62-.22v-.21l-.61.04c-.35.02-.5.14-.5.36s.2.36.47.36c.33.03.62-.2.65-.53,0,0,0-.01,0-.02Z"
    //           />
    //           <path
    //             class="cls-2"
    //             style={{strokeWidth: '0px', fill: '#fff'}}
    //             d="m39.89,6.96c0-.8.41-1.3,1.04-1.3.32-.01.62.16.77.44h.04v-1.38h.5v3.5h-.48v-.4h-.04c-.16.28-.47.45-.79.44-.64,0-1.04-.5-1.04-1.3Zm.51,0c0,.53.25.86.67.86s.68-.33.68-.85-.26-.86-.68-.86-.67.32-.67.85h0Z"
    //           />
    //           <path
    //             class="cls-2"
    //             style={{strokeWidth: '0px', fill: '#fff'}}
    //             d="m44.29,6.96c-.06-.66.42-1.24,1.07-1.3s1.24.42,1.3,1.07c0,.08,0,.15,0,.23.06.66-.42,1.24-1.07,1.3-.66.06-1.24-.42-1.3-1.07,0-.08,0-.15,0-.23Zm1.86,0c0-.55-.25-.86-.68-.86s-.67.32-.67.86.24.87.67.87c.43,0,.68-.32.68-.87Z"
    //           />
    //           <path
    //             class="cls-2"
    //             style={{strokeWidth: '0px', fill: '#fff'}}
    //             d="m47.33,5.7h.48v.4h.04c.13-.29.43-.47.75-.45.45-.03.84.3.88.76,0,.06,0,.12,0,.18v1.63h-.5v-1.5c0-.4-.18-.61-.54-.61-.32-.01-.59.23-.6.55,0,.03,0,.06,0,.09v1.47h-.5v-2.52Z"
    //           />
    //           <path
    //             class="cls-2"
    //             style={{strokeWidth: '0px', fill: '#fff'}}
    //             d="m52.28,5.07v.64h.55v.42h-.55v1.29c0,.26.11.38.36.38.06,0,.13,0,.19-.01v.41c-.09.02-.18.02-.27.03-.55,0-.77-.19-.77-.68v-1.42h-.4v-.42h.4v-.64h.5Z"
    //           />
    //           <path
    //             class="cls-2"
    //             style={{strokeWidth: '0px', fill: '#fff'}}
    //             d="m53.5,4.72h.49v1.39h.04c.14-.3.44-.48.77-.45.46-.02.85.33.87.78,0,.05,0,.1,0,.16v1.63h-.5v-1.5c0-.4-.19-.61-.54-.61-.32-.03-.61.21-.63.54,0,.03,0,.07,0,.1v1.47h-.5v-3.5Z"
    //           />
    //           <path
    //             class="cls-2"
    //             style={{strokeWidth: '0px', fill: '#fff'}}
    //             d="m58.57,7.54c-.14.47-.6.78-1.09.73-.63.02-1.16-.48-1.17-1.11,0-.06,0-.12.01-.19-.09-.64.36-1.22,1-1.3.05,0,.11-.01.16-.01.7,0,1.12.48,1.12,1.27v.17h-1.78v.03c-.03.37.24.69.61.72.02,0,.04,0,.06,0,.24.03.48-.09.6-.31h.48Zm-1.75-.81h1.27c.02-.33-.23-.63-.56-.65-.02,0-.04,0-.06,0-.36,0-.65.28-.65.64,0,0,0,.01,0,.02h0Z"
    //           />
    //         </g>
    //       </g>
    //     </g>
    //   </g>
    // </svg>
    <img id="appstore-btn" src={appstore} alt="" />
  );
};

export default AppStoreButton;
