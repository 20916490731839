import React, { Fragment, useEffect } from "react";
import cardBackground from "../../assets/cardbackground.png";

export default function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <div class="bg-gradient-to-b h-fit from-white to-gray-100 font-figtree scroll-smooth">
        {/* <div class="absolute inset-x-0 top-0 z-5 opacity-20 flex">
          <img class=" w-full" src={cardBackground} alt="" />
        </div> */}
        <div class="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8 relative">
          <div class="text-center">
            {/* <h2 class="text-lg font-semibold text-indigo-600"></h2> */}
            <p class="mt-1 text-4xl font-bold tracking-tight text-green-600  sm:text-5xl lg:text-6xl">
              Privacy Policy
            </p>
            <p class="mx-auto mt-5 max-w-xl text-xl text-gray-700">
              If you have an issue with these policies, you can always{" "}
              <a
                className="text-green-500"
                href="mailto:support@tapngogh.com?subject=ENQURIES ABOUT TAPNGO"
              >
                send us an email
              </a>{" "}
              with your enquiry.
            </p>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl  pt-10">
        <h1 className="font-bold text-2xl text-green-600">Privacy Policy</h1>
        <p>Last updated: May 14, 2023</p>

       

        <ul>
          <li>
            <a
              href="mailto:support@tapngogh.com"
              className="text-green-400 text-md"
            >
              By email: support@tapngogh.com
            </a>
          </li>
        </ul>
      </div>
    </Fragment>
  );
}
