import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Avatar,
  Divider,
  notification,
  Col,
  Row,
  Modal,
  message,
  Typography,
} from "antd";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import logo from "../../assets/logo.png";
import { PageLoader } from "../../components/Loaders";
import useRequest from "../../utils/Request";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { money } from "../../utils";
import empty from "../../assets/empty.png";
import occupied from "../../assets/occupied.png";
import selected from "../../assets/selected.png";
import Button from "../../components/Button";
import axios from "axios";
import cookiesConfig, { TICKETAPI } from "../../utils/cookies.config";

class PrintReceipt extends React.Component {
  render() {
    const { bookings, nCheckoutID } = this.props;

    const getTotals = () => {
      let total = 0;
      for (var i = 0; i < bookings.length; i++) {
        total += bookings[i].amount;
      }

      return total;
    };
    return (
      <div>
        {/* <img
          style={{ opacity: 0.6 }}
          src="https://tailwindui.com/img/beams-basic.png"
          className="absolute z-0    -ml-[39rem] w-[113.125rem] max-w-none"
        /> */}
        <div
          className="z-10  px-10  bg-fixed h-screen pt-10 "
          style={
            {
              //   backgroundPosition: "bottom-right",
              //   paddingTop: 20,
              //   backgroundOrigin: "content-box",
              //   backgroundImage: "url(https://tailwindui.com/img/beams-basic.png)",
            }
          }
        >
          <div className="relative mx-auto rounded-md border shadow-md max-w-4xl py-12 px-4 sm:py-10 sm:px-6 lg:px-8">
            <div className="grid gap-4 sm:grid-cols-1 lg:grid-cols-2">
              <div className="flex-1 flex align-center">
                <Avatar size={100} src={bookings[0]?.companyID?.img} />
                {/* <img className="rounded-full" src={logo} alt="logo" /> */}
                <div className=" justify-center px-10">
                  <h1 className="font-bold text-black text-xl">
                    {bookings[0]?.companyID?.name}{" "}
                  </h1>
                  <h1 className="font-medium text-gray-700 text-md">
                    eTicket Receipt{" "}
                  </h1>
                  <h1 className="font-medium text-gray-500 text-md">
                    support@tapngogh.com
                  </h1>
                  <h1 className="font-medium text-gray-500 text-md">
                    *713*091#
                  </h1>
                  <h1 className="font-medium text-gray-500 text-md">
                    {moment(bookings[0]?.createdAt).format(
                      "MMM, Do YYYY hh:mm:ss A"
                    )}
                  </h1>
                </div>
              </div>
            </div>

            <div className="flex flex-row sm:flex-col md:flex-col "></div>
            <Divider />
            <div>
              <div class="flex sm:items-center">
                <div class="flex-auto flex-1">
                  <h1 class="text-xl font-semibold text-gray-900">Receipt</h1>
                </div>
                <div class=" sm:mt-0 sm:ml-16 sm:flex-none">
                  {/* <button
                    type="button"
                    class="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                  >
                    Print Receipt
                  </button> */}
                </div>
              </div>
              <div class="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
                <table class="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                      >
                        Item Description
                      </th>
                      <th
                        scope="col"
                        class="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                      >
                        Tickets
                      </th>
                      <th
                        scope="col"
                        class="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                      >
                        Rate
                      </th>
                      <th
                        scope="col"
                        class="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                      >
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b border-gray-200">
                      <td class="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                        <div class="font-medium text-gray-900">Bus Ticket</div>
                        <div class="mt-0.5 text-gray-500 sm:hidden">
                          {bookings.length == 1
                            ? `x1 ticket`
                            : `${bookings?.length} tickets`}
                        </div>
                      </td>
                      <td class="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                        x{bookings?.length}
                      </td>
                      <td class="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                        GH&cent; {bookings[0]?.amount.toFixed(2)}
                      </td>
                      <td class="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                        GH&cent;{" "}
                        {(bookings?.length * bookings[0]?.amount).toFixed(2)}
                      </td>
                    </tr>

                    {/* <!-- More projects... --> */}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th
                        scope="row"
                        colspan="3"
                        class="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                      >
                        Subtotal
                      </th>
                      <th
                        scope="row"
                        class="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden"
                      >
                        Subtotal
                      </th>
                      <td class="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                        GH&cent; {money(getTotals().toFixed(2))}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        colspan="3"
                        class="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                      >
                        Charges
                      </th>
                      <th
                        scope="row"
                        class="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
                      >
                        Charges
                      </th>
                      <td class="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                        GH&cent;{" "}
                        {money(bookings[0]?.paymentInfo?.Amount - getTotals())}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        colspan="3"
                        class="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
                      >
                        Total
                      </th>
                      <th
                        scope="row"
                        class="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
                      >
                        Total
                      </th>
                      <td class="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                        GH&cent; {money(bookings[0]?.paymentInfo?.Amount)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <Divider />
            <h1 className="text-3xl font-bold mb-5 text-bold">Tickets</h1>
            {bookings.map((item) => (
              <div className=" border shadow-sm rounded-md mb-5 p-5">
                <div class="relative focus-within:ring-2 focus-within:ring-indigo-500">
                  <div class="flex items-center justify-between">
                    <p class="truncate text-lg font-medium text-gray-600">
                      {item?.origin} to {item?.destination}
                    </p>
                    <div class="ml-2 flex flex-shrink-0">
                      <p
                        class={`inline-flex rounded-full bg-${
                          item?.status === "pending" ? "red" : "green"
                        }-400 px-2 text-xs font-semibold leading-5 text-${
                          item?.status === "pending" ? "red" : "green"
                        }-800`}
                      >
                        {item?.status.toUpperCase()}
                      </p>
                    </div>
                  </div>

                  <div class="mt-2 sm:flex sm:justify-between">
                    <div class="pb-1 flex sm:pb-3">
                      <p class="flex items-center text-sm text-gray-500">
                        {/* <!-- Heroicon name: mini/users --> */}
                        <svg
                          class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M7 8a3 3 0 100-6 3 3 0 000 6zM14.5 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM1.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 017 18a9.953 9.953 0 01-5.385-1.572zM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 00-1.588-3.755 4.502 4.502 0 015.874 2.636.818.818 0 01-.36.98A7.465 7.465 0 0114.5 16z" />
                        </svg>
                        {item?.scheduled ? item?.seatNumber : "-"}
                      </p>
                      <p class=" flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        {/* <!-- Heroicon name: mini/map-pin --> */}
                        <svg
                          class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        {item?.pickupLocation}
                      </p>
                      <p class=" flex items-center text-sm text-green-800 sm:mt-0 sm:ml-6">
                        {/* <!-- Heroicon name: mini/map-pin --> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                          />
                        </svg>
                        {item?.bookingID}
                      </p>
                    </div>
                    <div class=" flex items-center text-sm text-gray-500 sm:mt-0">
                      {/* <!-- Heroicon name: mini/calendar --> */}
                      <svg
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <div>
                        <p className="font-bold">
                          Bus{" "}
                          {moment().isAfter(item?.departureDate)
                            ? "moved"
                            : "moves "}{" "}
                          at{" "}
                          <time datetime={item?.departureDate}>
                            {item?.scheduled
                              ? moment(item?.departureDate).format(
                                  "MMM, Do YYYY hh:mm A"
                                )
                              : moment(item?.departureDate).format(
                                  "MMM, Do YYYY"
                                )}
                          </time>
                        </p>
                        <p>
                          {" "}
                          {item?.scheduled
                            ? moment(item?.departureDate).fromNow()
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Divider /> */}
              </div>
            ))}
            <Divider />
            <footer class="">
              <div class="mx-auto max-w-7xl overflow-hidden   px-4 sm:px-6 lg:px-8">
                <center>
                  <img
                    class="max-h-16 w-30 h-16"
                    src="https://vip.itmsgh.com/static/media/calbank.64dea078.png"
                    alt="Calbank PLC"
                  />
                </center>

                <p class="mt-2 text-center text-base text-gray-400">
                  &copy; {new Date().getFullYear()} Calbank PLC. All rights
                  reserved.
                </p>
                <center>
                  <a
                    href="mailto:support@itmsgh.com"
                    class="mt-2 text-center text-base text-gray-400"
                  >
                    support@tapngo.com
                  </a>
                </center>
                <center>
                  <a
                    href="https://app.vipbustickets.com/qh74"
                    class="mt-2 text-center text-base text-gray-400"
                  >
                    Download Tap & Go App now
                  </a>
                </center>
                <center>
                  <a
                    href="https://app.vipbustickets.com/qh74"
                    class="mt-2 text-center text-base text-gray-400"
                  >
                    {moment().format("dddd, Do MMMM YYYY HH:mm")}
                  </a>
                </center>
              </div>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

const { confirm } = Modal;

export default function Ticket(props) {
  const location = useLocation();
  const [nCheckoutID, setCheckoutID] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [seats, setSeats] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  let [isOpen, setIsOpen] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState();

  const componentRef = useRef();

  const [fetchBookingHistory, { loading: loadingHistory }] = useRequest(
    { path: "/trips/bookings/history", api: 2, method: "get" },
    {
      onCompleted: (d) => {
        // console.log(d?.data);
        setBookings(d?.data);
      },
      onError: (e) => {
        notification.error({
          message: e?.reponse?.data,
          placement: "bottomRight",
        });
      },
    }
  );

  const [availableSeats, setAvailableSeats] = useState([]);
  const [selectedDepartureTime, setSelectedDepartureTIme] = useState();
  const [bookingTrip, setBookingTrip] = useState(false);

  const rightseats = [
    2, 6, 10, 14, 18, 22, 26, 30, 34, 38, 40, 42, 46, 54, 58, 62,
  ];
  const leftSeats = [3, 7, 11, 15, 19, 23, 27, 31, 35, 43, 47];

  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  const [getAvailableSeats, { loading: loadingAvailableSeats }] = useRequest(
    { path: "/trips/advanced/availableSeats", api: 2, method: "get" },
    {
      onCompleted: (d) => {
        setAvailableSeats(d?.data);

        if (d?.data.length > 0) {
          let item = d?.data[0];
          setSelectedDepartureTIme(item);
          setIsOpen(true);
          // setShowDepartureTime(false);

          let nSeats = [];
          for (var i = 1; i <= 55; i++) {
            if (!item.numberOfSeats.includes(i)) {
              nSeats.push({
                seat: i,
                status: "available",
              });
            } else {
              nSeats.push({
                seat: i,
                status: "occupied",
              });
            }
          }
          setSeats(nSeats);
        }
      },
      onError: (e) => {
        notification.error({
          message: e?.reponse?.data,
          placement: "bottomRight",
        });
      },
    }
  );

  async function getBookingHistory() {
    const params = new URLSearchParams(location.search);
    const checkoutID = params.get("checkoutid");

    // console.log(checkoutID);
    setCheckoutID(checkoutID);
    fetchBookingHistory({ reference: checkoutID });
  }

  function getTotals() {
    let total = 0;
    for (var i = 0; i < bookings.length; i++) {
      total += bookings[i].amount;
    }

    return total;
  }

  useEffect(() => {
    Promise.all([getBookingHistory()]);
  }, []);

  const confirmRefund = (trip) => {
    confirm({
      title: "Refund money?",
      icon: <ExclamationCircleOutlined />,
      content: `We'll send a request for funds to be reversed. Your ticket will be null and void. Amount will be refunded to ${trip?.paymentInfo?.PaymentDetails?.MobileMoneyNumber} Do you want to continue?`,
      async onOk() {
        const payload = {
          bookingID: trip?.bookingID,
          purpose: "seatOccupiedRefund",
        };

        try {
          const n = await axios.post(
            `${TICKETAPI}/trip/advanced/cancel-ticket`,
            payload,
            {
              headers: { token: cookiesConfig.getCipher() },
            }
          );
          getBookingHistory();
          message.success("Request submitted successfully, pending approval");
        } catch (e) {
        //   console.log(e?.response);
          getBookingHistory();
          notification.error({
            message: e?.response?.data,
            placement: "bottomRight",
          });
        }
      },
      onCancel() {},
    });
  };

  return (
    <Fragment>
      <Modal
        destroyOnClose
        visible={isOpen}
        footer={null}
        centered
        maskClosable={false}
        width={500}
        title={
          <div>
            <Typography.Text>Select another available seat</Typography.Text>{" "}
            <br />
            <Typography.Text strong>
              Seat Number: {selectedSeats.map((s) => s)}
            </Typography.Text>
          </div>
        }
        // bodyStyle={{ height: 700 }}
        onCancel={() => {
          setSelectedDepartureTIme(false);

          setBookingTrip(false);
          setIsOpen(false);
          setSelectedSeats([]);
        }}
      >
        <Fragment>
          <div className="font-nunito">
            <Row gutter={10}>
              <Col md={24}>
                <div
                  style={{
                    paddingLeft: window.screen.width <= 640 ? 20 : 108,
                    paddingRight: window.screen.width <= 640 ? 20 : 108,
                  }}
                  class="grid   pt-2  grid-flow-row place-content-center mx-auto gap-2  grid-cols-4"
                >
                  <div className="  justify-center  p-1 rounded-md">
                    <img
                      className="w-8 h-8"
                      src="https://images.cdn.circlesix.co/image/1/640/0/uploads/articles/mp4-4_1-56ba177f49685.jpg"
                    />
                  </div>
                  <div className="col-start-3 pl-6 justify-center flex p-1 rounded-md">
                    <img className="w-8 h-8" src={occupied} />
                  </div>
                  <div className=" justify-center flex p-1 rounded-md">
                    <img className="w-8 h-8" src={occupied} />
                  </div>
                </div>
                <div
                  style={{
                    paddingLeft: window.screen.width <= 640 ? 20 : 100,
                    paddingRight: window.screen.width <= 640 ? 20 : 100,
                  }}
                  class="grid  pt-2 grid-flow-row place-content-center mx-auto gap-2  grid-cols-4"
                >
                  {seats.slice(0, -7).map((s) =>
                    s.seat == 39 || s?.seat == 41 ? (
                      <div
                        onClick={() => {
                          if (s?.status === "available") {
                            if (selectedSeats.includes(s?.seat)) {
                              setSelectedSeats(
                                selectedSeats.filter((i) => i !== s?.seat)
                              );
                            } else setSelectedSeats([s.seat]);
                          }
                        }}
                        className="col-start-1  justify-center flex p-1 rounded-md"
                      >
                        {selectedSeats.includes(s?.seat) ? (
                          <img className="w-8 h-8" src={selected} />
                        ) : s?.status === "available" ? (
                          <img className="w-8 h-8" src={empty} />
                        ) : (
                          <img className="w-8 h-8" src={occupied} />
                        )}
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          if (s?.status === "available") {
                            if (selectedSeats.includes(s?.seat)) {
                              setSelectedSeats(
                                selectedSeats.filter((i) => i !== s?.seat)
                              );
                            } else {
                              setSelectedSeats([s.seat]);
                            }
                          }
                        }}
                        className={` justify-center flex ${
                          rightseats.includes(s?.seat)
                            ? "mr-5 px-0"
                            : leftSeats.includes(s?.seat)
                            ? "ml-5 px-0"
                            : "mr-0 px-1"
                        }  rounded-md `}
                      >
                        {/* {s?.seat} */}

                        {selectedSeats.includes(s?.seat) ? (
                          <img className="w-8 h-8" src={selected} />
                        ) : s?.status === "available" ? (
                          <img className="w-8 h-8" src={empty} />
                        ) : (
                          <img className="w-8 h-8" src={occupied} />
                        )}
                      </div>
                    )
                  )}
                </div>
                <div
                  style={{
                    paddingLeft: window.screen.width <= 640 ? 20 : 108,
                    paddingRight: window.screen.width <= 640 ? 20 : 108,
                  }}
                  class="grid   pt-2  grid-flow-row place-content-center mx-auto gap-2  grid-cols-5"
                >
                  {seats.slice(-7, -2).map((s) => (
                    <div
                      style={{}}
                      onClick={() => {
                        if (s?.status === "available") {
                          if (selectedSeats.includes(s?.seat)) {
                            setSelectedSeats(
                              selectedSeats.filter((i) => i !== s?.seat)
                            );
                          } else {
                            setSelectedSeats([s.seat]);
                          }
                        }
                      }}
                      className=" justify-center flex p-1 rounded-md"
                    >
                      {selectedSeats.includes(s?.seat) ? (
                        <img className="w-8 h-8" src={selected} />
                      ) : s?.status === "available" ? (
                        <img className="w-8 h-8" src={empty} />
                      ) : (
                        <img className="w-8 h-8" src={occupied} />
                      )}
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            <center className="pt-2">
              {selectedSeats.length === 1 && (
                <Button
                  onClick={async () => {
                    setBookingTrip(true);

                    try {
                      const n = await axios.post(
                        `${TICKETAPI}/trip/advanced/change-seat`,
                        {
                          bookingID: selectedTrip?.bookingID,
                          seatNumber: selectedSeats[0],
                        },
                        {
                          headers: { token: cookiesConfig.getCipher() },
                        }
                      );

                      // console.log(n?.data);
                      setIsOpen(false);
                      setSelectedSeats([]);
                      setSelectedTrip(null);
                      getBookingHistory();

                      notification.success({
                        message: "Ticket changed successfully",
                        placement: "bottomRight",
                      });
                      // getAdvancedTripDetails(date.toDateString());
                      // setBookTrip(false);
                    } catch (e) {
                      setBookingTrip(false);
                      notification.error({
                        message: e.message,
                        placement: "bottomRight",
                      });
                    }
                  }}
                  loading={bookingTrip}
                  title="Confirm Seat"
                />
              )}
            </center>
          </div>
        </Fragment>
      </Modal>
      <img
        style={{ opacity: 0.6 }}
        src="https://tailwindui.com/img/beams-basic.png"
        className="absolute z-0  w-full h-full  -ml-[39rem] "
      />
      <div
        className="z-10  h-screen   pt-10 "
        style={
          {
            //   backgroundPosition: "bottom-right",
            //   paddingTop: 20,
            //   backgroundOrigin: "content-box",
            //   backgroundImage: "url(https://tailwindui.com/img/beams-basic.png)",
          }
        }
      >
        {loadingHistory ? (
          <PageLoader />
        ) : bookings.length == 0 ? (
          <div class="text-center z-20">
            <svg
              class="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vector-effect="non-scaling-stroke"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 class="mt-2 text-sm font-medium text-gray-900">
              No Receipt found
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              Sorry, there's something wrong with your checkout ID / Booking ID
            </p>
          </div>
        ) : (
          <div className="relative mx-auto rounded-md border shadow-md max-w-4xl py-12 px-4 sm:py-10 sm:px-6 lg:px-8">
            <div className="grid gap-4 sm:grid-cols-1 lg:grid-cols-2">
              <div className="flex-1 flex align-center">
                <Avatar size={100} src={bookings[0]?.companyID?.img} />
                {/* <img className="rounded-full" src={logo} alt="logo" /> */}
                <div className=" justify-center px-10">
                  <h1 className="font-bold text-black text-xl">
                    {bookings[0]?.companyID.name}{" "}
                  </h1>
                  <h1 className="font-medium text-gray-700 text-md">
                    eTicket Receipt{" "}
                  </h1>
                  <h1 className="font-medium text-gray-500 text-md">
                    support@tapngogh.com
                  </h1>
                </div>
              </div>
              <div className="mx-auto ">
                <dl className="hidden sm:hidden lg:block">
                  <dt class="text-sm font-medium text-red-500">
                    Checkout Reference
                  </dt>
                  <dd class="mt-0.5 text-sm text-gray-500">{nCheckoutID}</dd>
                </dl>
                <dl className="hidden sm:hidden lg:block">
                  <dt class="text-sm font-medium text-red-500">
                    Transaction Date
                  </dt>
                  <dd class="mt-0.5 text-sm text-gray-500">
                    {moment(bookings[0]?.createdAt).format(
                      "MMM, Do YYYY hh:mm:ss A"
                    )}
                  </dd>
                </dl>
              </div>
            </div>

            <div className="flex flex-row sm:flex-col md:flex-col "></div>
            <Divider />
            <div>
              <div class="flex sm:items-center">
                <div class="flex-auto flex-1">
                  <h1 class="text-xl font-semibold text-gray-900">Receipt</h1>
                </div>
                <div class=" sm:mt-0 sm:ml-16 sm:flex-none">
                  <ReactToPrint
                    trigger={() => (
                      <button
                        // onClick={handlePrint}
                        type="button"
                        class="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none  sm:w-auto"
                      >
                        Print Receipt
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                </div>
              </div>
              <div class="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
                <table class="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                      >
                        Item
                      </th>
                      <th
                        scope="col"
                        class="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                      >
                        Tickets
                      </th>
                      <th
                        scope="col"
                        class="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                      >
                        Rate
                      </th>
                      <th
                        scope="col"
                        class="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                      >
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b border-gray-200">
                      <td class="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                        <div class="font-medium text-gray-900">Bus Ticket</div>
                        <div class="mt-0.5 text-gray-500 sm:hidden">
                          {bookings.length == 1
                            ? `x1 ticket`
                            : `${bookings?.length} tickets`}
                        </div>
                      </td>
                      <td class="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                        x{bookings?.length}
                      </td>
                      <td class="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                        GH&cent; {money(bookings[0]?.amount)}
                      </td>
                      <td class="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                        GH&cent; {money(bookings?.length * bookings[0]?.amount)}
                      </td>
                    </tr>

                    {/* <!-- More projects... --> */}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th
                        scope="row"
                        colspan="3"
                        class="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                      >
                        Subtotal
                      </th>
                      <th
                        scope="row"
                        class="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden"
                      >
                        Subtotal
                      </th>
                      <td class="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                        GH&cent; {money(getTotals())}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        colspan="3"
                        class="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                      >
                        Charges
                      </th>
                      <th
                        scope="row"
                        class="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
                      >
                        Charges
                      </th>
                      <td class="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                        GH&cent;{" "}
                        {money(bookings[0]?.paymentInfo?.Amount - getTotals())}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        colspan="3"
                        class="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
                      >
                        Total
                      </th>
                      <th
                        scope="row"
                        class="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
                      >
                        Total
                      </th>
                      <td class="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                        GH&cent; {money(bookings[0]?.paymentInfo?.Amount)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <Divider />
            <h1 className="text-3xl font-bold mb-5 text-bold">Tickets</h1>
            {bookings.map((item, index) => (
              <div
                key={index + 1}
                className=" border shadow-sm rounded-md mb-5 p-5"
              >
                <div class="relative ">
                  <div class="flex items-center ">
                    <p class="truncate flex-1 text-lg font-medium text-gray-600">
                      {item?.origin} to {item?.destination}
                    </p>

                    <div class="ml-2 flex flex-shrink-0">
                      <p
                        class={`inline-flex rounded-full bg-${
                          item?.status === "pending"
                            ? "red"
                            : item?.status === "pending-refund"
                            ? "gray"
                            : "green"
                        }-400 px-2 text-xs font-semibold leading-5 text-${
                          item?.status === "pending"
                            ? "red"
                            : item?.status === "pending-refund"
                            ? "gray"
                            : "green"
                        }-800`}
                      >
                        {item?.status.toUpperCase()}
                      </p>
                    </div>

                    {!moment().isAfter(item?.departureDate) &&
                      !item?.refunded &&
                      item?.seatOccupied &&
                      item?.status === "pending" && (
                        <div class="ml-2 flex flex-shrink-0">
                          <button
                            disabled={loadingAvailableSeats}
                            onClick={() => {
                              setSelectedTrip(item);
                              const payload = {
                                departureTimes: JSON.stringify([
                                  item?.departureDate,
                                ]),
                                tripID: item?.tripID,
                              };

                              getAvailableSeats(payload);
                            }}
                            class={`inline-flex rounded-full bg-green-800 px-2 text-xs font-semibold focus:outline-none leading-5 text-white`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-4 h-4 text-white pt-0.5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                              />
                            </svg>
                            {loadingAvailableSeats
                              ? "LOADING..."
                              : "SELECT ANOTHER SEAT"}
                          </button>{" "}
                        </div>
                      )}

                    {!item?.refunded &&
                      item?.seatOccupied &&
                      !item?.refundRequested &&
                      item?.status === "pending" && (
                        <div class="ml-2 flex flex-shrink-0">
                          <button
                            onClick={() => {
                              // setSelectedTrip(item);
                              confirmRefund(item);
                            }}
                            class={`inline-flex rounded-full justify-center bg-green-800 px-2 text-xs font-semibold leading-5 text-white`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-4 h-4 text-white pt-0.5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M8.25 9.75h4.875a2.625 2.625 0 010 5.25H12M8.25 9.75L10.5 7.5M8.25 9.75L10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185z"
                              />
                            </svg>
                            REFUND
                          </button>{" "}
                        </div>
                      )}
                  </div>

                  <div class="mt-2 sm:flex sm:justify-between">
                    <div class="pb-1 flex sm:pb-3">
                      <p class="flex items-center text-sm text-gray-500">
                        {/* <!-- Heroicon name: mini/users --> */}
                        <svg
                          class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M7 8a3 3 0 100-6 3 3 0 000 6zM14.5 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM1.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 017 18a9.953 9.953 0 01-5.385-1.572zM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 00-1.588-3.755 4.502 4.502 0 015.874 2.636.818.818 0 01-.36.98A7.465 7.465 0 0114.5 16z" />
                        </svg>
                        {item?.scheduled ? item?.seatNumber : "-"}
                      </p>
                      <p class=" flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        {/* <!-- Heroicon name: mini/map-pin --> */}
                        <svg
                          class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        {item?.pickupLocation}
                      </p>
                      <p class=" flex items-center text-sm text-green-800 sm:mt-0 sm:ml-6">
                        {/* <!-- Heroicon name: mini/map-pin --> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                          />
                        </svg>
                        {item?.bookingID}
                      </p>
                    </div>
                    <div class=" flex items-center text-sm text-gray-500 sm:mt-0">
                      {/* <!-- Heroicon name: mini/calendar --> */}
                      <svg
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <div>
                        <p className="font-bold">
                          Bus{" "}
                          {moment().isAfter(item?.departureDate)
                            ? "moved"
                            : "moves "}{" "}
                          at{" "}
                          <time datetime={item?.departureDate}>
                            {item?.scheduled
                              ? moment(item?.departureDate).format(
                                  "MMM, Do YYYY hh:mm A"
                                )
                              : moment(item?.departureDate).format(
                                  "MMM, Do YYYY"
                                )}
                          </time>
                        </p>
                        <p>
                          {" "}
                          {item?.scheduled
                            ? moment(item?.departureDate).fromNow()
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {item?.seatOccupied && (
                  <p class="truncate flex-1 text-sm  text-red-500">
                    Sorry! Unfortunately someone took your seat before you made
                    payment
                  </p>
                )}
              </div>
            ))}
            <Divider />

           
          </div>
        )}
      </div>
      <div className="hidden">
        <PrintReceipt bookings={bookings} nCheckoutID ref={componentRef} />
      </div>
    </Fragment>
  );
}
